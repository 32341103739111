<template>
    <v-content>
        <v-snackbar :timeout="callbackResponse.timeout" :color="callbackResponse.color" top v-model="snackbar">
            {{callbackResponse.message}}
            <v-btn @click="snackbar = false" dark text>Close</v-btn>
        </v-snackbar>

        <v-dialog max-width="500px" v-model="dialogDelete">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <br />
        <div>
      <div class="d-flex py-4">
        <v-spacer></v-spacer>
        <JsonExcelVue :fetch="fetchData" :header="header"  is-raw-headers :rawHeaders="rawHeaders">
          <v-btn small class="success mx-4">
            <v-icon>get_app</v-icon>
            {{$t('download_to_excel')}}
          </v-btn>
        </JsonExcelVue>
      </div>
            <v-data-table :headers="columns"
                          :items="rows"
                          :options.sync="options"
                          :server-items-length="totalRecords"
                          :loading="loading"
                          class="elevation-1"
                          hide-default-header
                          hide-default-footer>
                <template v-slot:item="props">
                    <tr class="text-center header-bg">
                        <th class="text-center border font-weight-black">
                            {{$t('economic_indicator')}}
                        </th>
                        <th v-for="(row,index) in dynamicColumnData" :key="'fiscal-year'+index">
                            <v-text-field dense
                                          v-model="row.fiscalYear"
                                          v-if="row.isEdit || row.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else class="d-flex justify-center mt-8">
                                <p class="font-weight-black">
                                    <span>
                                        {{row.fiscalYear}}
                                    </span>
                                </p>
                            </div>

                        </th>

                    </tr>
                    <tr class="text-center">
                        <td class="text-center border ">
                            {{$t('annual_growth_rate_at_cp')}}
                        </td>
                        <td v-for="row in dynamicColumnData" :key="row">
                            <v-text-field dense
                                          v-model="row.annualGrowthRate"
                                          v-if="row.isEdit || row.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{row.annualGrowthRate}}</div>

                        </td>



                    </tr>
                    <tr class="text-center">
                        <td class="text-center border ">
                            {{$t('gross_output_at_cp')}}
                        </td>
                        <td v-for="row in dynamicColumnData" :key="row">
                            <v-text-field dense
                                          v-model="row.grossOutput"
                                          v-if="row.isEdit || row.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{row.grossOutput}}</div>

                        </td>



                    </tr>
                    <tr class="text-center">
                        <td class="text-center border ">
                            {{$t('intermediate_consumption_at_cp')}}
                        </td>
                        <td v-for="row in dynamicColumnData" :key="row">
                            <v-text-field dense
                                          v-model="row.intermediateConsumption"
                                          v-if="row.isEdit || row.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{row.intermediateConsumption}}</div>

                        </td>


                    </tr>
                    <tr class="text-center">
                        <td class="text-center border ">
                            {{$t('gross_value_added_at_cp')}}
                        </td>
                        <td v-for="row in dynamicColumnData" :key="row">
                            <v-text-field dense
                                          v-model="row.gvaCurrentPrice"
                                          v-if="row.isEdit || row.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{row.gvaCurrentPrice}}</div>

                        </td>


                    </tr>
                    <tr class="text-center">
                        <td class="text-center border ">
                            {{$t('gvacp')}}
                        </td>
                        <td v-for="row in dynamicColumnData" :key="row">
                            <v-text-field dense
                                          v-model="row.gvaConstantPrice"
                                          v-if="row.isEdit || row.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{row.gvaConstantPrice}}</div>

                        </td>


                    </tr>
                    <tr class="text-center">
                        <td class="text-center border ">
                            {{$t('pgdp')}}
                        </td>
                        <td v-for="row in dynamicColumnData" :key="row">
                            <v-text-field dense
                                          v-model="row.proportionOfGDP"
                                          v-if="row.isEdit || row.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{row.proportionOfGDP}}</div>

                        </td>


                    </tr>
                    <tr class="text-center">
                        <td class="text-center border ">
                            {{$t('gdpd')}}
                        </td>
                        <td v-for="row in dynamicColumnData" :key="row">
                            <v-text-field dense
                                          v-model="row.gdpDeflator"
                                          v-if="row.isEdit || row.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{row.gdpDeflator}}</div>

                        </td>


                    </tr>


                </template>

            </v-data-table>
            <v-row class="mt-7">
                <div class="col-12" style="color: gray; text-align: right">
                    {{$t('source')}} : {{$t('national_statistics_office')}}
                </div>
            </v-row>
        </div>

    </v-content>
</template>

<script>
    import axios from "axios";
    //import sectorForm from "./create";
    //import editSectorForm from "./edit";
    import deleteListData from "@/components/deleteModal";
    import JsonExcelVue from "@/components/ImportExport/JsonExcel.vue"

    export default {
        name: "CentralBureau",
        components: {
            //sectorForm,
            //editSectorForm,
          JsonExcelVue,
            deleteListData
        },
        computed: {
            breadCrumb() {
                return [
                    {
                        text: this.$t('report'),
                        disabled: false,
                        to: '/reportModules',
                        exact: true

                    },
                    {
                        text: this.$t('economic_indicators_of_accomodation_and_food_services_activities'),
                        disabled: true,
                    },
                ]
            }
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000,
                    color: ''
                },
                isCreate: false,
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                deleteFormData: {},
                popout: true,
                loading: false,
                isLoading: false,
                columns: [
                    {
                        label: "Fiscal Year",
                        field: "fiscalYear"
                    },
                    {
                        label: "NRs. (Million)",
                        field: "nrs"
                    },
                    {
                        label: "Exchange Rate",
                        field: "exchangeRate"
                    },
                    {
                        label: "US$ (Million)",
                        field: "amountUsd"
                    },
                    {
                        label: "%Change in US$",
                        field: "change"
                    }
                ],
                rows: [{
                    fiscalYear: '',
                    annualGrowthRate: '',
                    grossOutput: '',
                    intermediateConsumption: '',
                    gvaCurrentPrice: '',
                    gvaConstantPrice: '',
                    proportionOfGDP: '',
                    gdpDeflator: '',
                    isEdit: false,
                    isCreate: true,
                }],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "fiscalYear",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    serviceID: "",
                    serviceName: "",
                    serviceDescription: ""
                },
                dynamicColumnData: [],
              header:"",
              rawHeaders:""
            };
        },

        mounted() {
            this.loadItems();
        },
        methods: {
            fetchData(){
              this.header = this.$t('economic_indicators_of_accomodation_and_food_services_activities')
              const columns = [{
                text: this.$t('annual_growth_rate_at_cp'),
                key:"annualGrowthRate",
              },
                {
                  text: this.$t('gross_output_at_cp'),
                  key:"grossOutput",
                },
                {
                  text: this.$t('intermediate_consumption_at_cp'),
                  key:"intermediateConsumption"
                },
                {
                  text: this.$t('gross_value_added_at_cp'),
                  key:"gvaCurrentPrice",
                },
                {
                  text: this.$t('gvacp'),
                  key:"gvaConstantPrice",
                },
                {
                  text: this.$t('pgdp'),
                  key:"proportionOfGDP",
                },
                {
                  text: this.$t('gdpd'),
                  key:"gdpDeflator",
                }
                ]
              let rowData = [];
              let rawHeader = `<tr>
                <th>
                  <tr>
                    <td>`+
                      this.$t('economic_indicator')
                    +`</td>`;
              columns.map(res=>{
                let singleRow ={}
                this.dynamicColumnData.map((column,key)=>{
                  singleRow[`data-${key}`] = column[res.key]
                })
                rowData.push({
                  indicator: res.text,
                  ...singleRow
                })
              })
              this.dynamicColumnData.map(res=>{
                rawHeader += `<td>${res.fiscalYear}</td>`
              })
              rawHeader +=`</tr>`
              this.rawHeaders = rawHeader;
              return rowData
            },
            async submit(data) {
                let param = {
                    annualGrowthRate: parseFloat(data.annualGrowthRate),
                    fiscalYear: data.fiscalYear.toString(),
                    grossOutput: parseFloat(data.grossOutput),
                    intermediateConsumption: parseFloat(data.intermediateConsumption),
                    gvaCurrentPrice: parseFloat(data.gvaCurrentPrice),
                    gvaConstantPrice: parseFloat(data.gvaConstantPrice),
                    proportionOfGDP: parseFloat(data.proportionOfGDP),
                    gdpDeflator: parseFloat(data.gdpDeflator)
                }
                await axios.post("NRBExchange/InsertIntoCentralBureauListAsync", param).then(response => {
                    if (response.data.success) {
                        this.snackbar = true
                        this.callbackResponse.data = response.data
                        this.callbackResponse.message = "Economic Indicators Data added Succcessfully"
                        this.callbackResponse.color = "success"

                    }

                }).catch(response => {
                    this.snackbar = true
                    this.callbackResponse.data = response.data
                    this.callbackResponse.message = "Error Adding Economic Indicators Data, Please Contact Admin"
                    this.callbackResponse.color = "error"
                });
                this.loadItems();

            },
            async submitEdit(data) {
                let param = {
                    annualGrowthRate: parseFloat(data.annualGrowthRate),
                    fiscalYear: data.fiscalYear.toString(),
                    grossOutput: parseFloat(data.grossOutput),
                    intermediateConsumption: parseFloat(data.intermediateConsumption),
                    gvaCurrentPrice: parseFloat(data.gvaCurrentPrice),
                    gvaConstantPrice: parseFloat(data.gvaConstantPrice),
                    proportionOfGDP: parseFloat(data.proportionOfGDP),
                    gdpDeflator: parseFloat(data.gdpDeflator),
                    iD: data.id
                }
                await axios.post("NRBExchange/UpdateCentralBureauListAsync", param).then(response => {
                    this.callbackResponse.data = response.data
                    this.snackbar = true
                    this.callbackResponse.message = "Data updated Successfully"
                    this.callbackResponse.color = "success"

                }).catch(response => {
                    this.snackbar = true
                    this.callbackResponse.data = response.data
                    this.callbackResponse.message = "Error Updating Data, Please Contact Admin"
                    this.callbackResponse.color = "error"
                });
                this.loadItems();

            },
            createRemoveArray(index) {
                this.dynamicColumnData.splice(index, 1)
            },
            addTableData() {
                this.dynamicColumnData.push({
                    fiscalYear: '',
                    annualGrowthRate: '',
                    grossOutput: '',
                    intermediateConsumption: '',
                    gvaCurrentPrice: '',
                    gvaConstantPrice: '',
                    proportionOfGDP: '',
                    gdpDeflator: '',
                    isEdit: false,
                    isCreate: true,
                })
            },
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            editItem(props) {
                this.editedData.iD = props.iD;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "NRBExchange/DeleteCentralBureauListAsync/" + props.id;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = {}
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                //console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                };
                axios.post("NRBExchange/GetCentralBureauListForAdminAsync", param)
                    .then(({ data }) => {
                        this.dynamicColumnData = data.data;
                        this.dynamicColumnData.map(res => {
                            this.$set(res, 'isEdit', false);
                            this.$set(res, 'isCreate', false);
                            return res;
                        })
                        this.totalRecords = data.totalCount;
                        if (this.dynamicColumnData.length == 0) {
                            this.addTableData();
                            this.totalRecords = 1
                        }

                    })
                    .catch(err => {
                        console.log(err)
                    });

                this.addTableData();

            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }

    ::v-deep .v-text-field, ::v-deep .v-text-field--solo {
        width: 100px !important;
    }

    ::v-deep .v-data-table__wrapper {
        width: 100% !important;
    }

    .border {
        border: 1px solid black !important;
    }

    table, td {
        height: 40px !important;
        border: 1px solid black !important;
        border-collapse: collapse;
    }

    .header-bg, thead {

        th {
            padding: 1rem !important;
            background-color: rgb(186 186 186 / 59%) !important;
            height: 40px !important;
            border: 1px solid black !important;
            text-align: center !important;
            border-color: white;
            font-size: 1rem !important;
        }
    }

    ::v-deep .v-text-field {
        width: 160px !important;
    }

    th.rotated-text > div > p {
        transform: rotate(-90deg);
        width: 30px;
    }
</style>
