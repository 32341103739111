<template>
    <v-content>
        <v-snackbar :timeout="callbackResponse.timeout" :color="callbackResponse.color" top v-model="snackbar">
            {{callbackResponse.message}}
            <v-btn @click="snackbar = false" dark text>Close</v-btn>
        </v-snackbar>

        <v-dialog max-width="500px" v-model="dialogDelete">
            <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
        </v-dialog>
        <br />
        <div>
            <div class="d-flex py-4">
                <v-spacer></v-spacer>
                <JsonExcelVue :fetch="fetchData" is-custom-header :customHeader="customHeader">
                    <v-btn small class="success mx-4">
                        <v-icon>get_app</v-icon>
                        {{$t('download_to_excel')}}
                    </v-btn>

                </JsonExcelVue>
            </div>
            <v-select class="mt-8"
                      :items="customYear"
                      dense
                      outlined
                      v-on:change="getChangedList(yearFromDate)"
                      :label="$t('select_year')"
                      v-model="yearFromDate"
                      prepend-inner-icon="event"></v-select>
            <v-data-table :headers="columns"
                          :items="rows"
                          :server-items-length="totalRecords"
                          :loading="loading"
                          class="elevation-1"
                          hide-default-header>

                <template v-slot:header="props">
                    <thead>
                        <tr class="text-center">
                            <th rowspan="2" class="text-center">{{$t('s_no')}}</th>
                            <th rowspan="2">{{$t('peak')}}</th>
                            <th rowspan="2">{{$t('permitted_team')}}</th>
                            <th colspan="2">{{$t('permitted_member')}}</th>
                            <th rowspan="2">{{$t('total_permitted_member')}}</th>
                            <th rowspan="2">{{$t('royalty_us')}}</th>

                        </tr>
                        <tr class="text-center">
                            <th rowspan="2">{{$t('male')}}</th>
                            <th rowspan="2">{{$t('female')}}</th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:item="props">
                    <tr class="text-center">
                        <td class="text-center border ">
                            <div> {{props.item.countIndex}}</div>
                        </td>
                        <td>
                            <v-text-field dense
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          v-model="props.item.peak"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.peak}}</div>
                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.permittedTeam"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.permittedTeam}}</div>

                        </td>

                        <td>
                            <v-text-field dense
                                          v-model="props.item.male"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.male}}</div>

                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.female"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.female}}</div>

                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.totalPermittedMember"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.totalPermittedMember}}</div>

                        </td>
                        <td>
                            <v-text-field dense
                                          v-model="props.item.royalty"
                                          v-if="props.item.isEdit || props.item.isCreate"
                                          class="shrink"></v-text-field>
                            <div v-else> {{props.item.royalty}}</div>

                        </td>

                    </tr>

                </template>
                <template slot="body.append">
                    <tr style="background-color: #808080b8;text-align:center">
                        <td colspan="2">Total</td>
                        <td>{{totals.totalPermittedTeam}}</td>
                        <td>{{totals.totalMale}}</td>
                        <td>{{totals.totalFemale}}</td>
                        <td>{{totals.totalPermittedMemberSum}}</td>
                        <td>{{totals.totalRoyalty}}</td>
                    </tr>
                </template>
            </v-data-table>
            <v-row class="mt-7">
                <div class="col-12" style="color: gray; text-align: right">
                    {{$t('source')}} : {{$t('n_m_a')}}
                </div>
            </v-row>
        </div>

    </v-content>
</template>

<script>
    import axios from "axios";
    //import sectorForm from "./create";
    //import editSectorForm from "./edit";
    import deleteListData from "@/components/deleteModal";
    import JsonExcelVue from "@/components/ImportExport/JsonExcel.vue"

    export default {
        name: "NMAReport",
        components: {
            //sectorForm,
            //editSectorForm,
            JsonExcelVue,
            deleteListData
        },
        computed: {
            columns() {
                return [
                    {
                        label: this.$t('s_no'),
                        field: "countIndex"
                    },
                    {
                        label: this.$t('peak'),
                        field: "peak"
                    },
                    {
                        label: this.$t('permitted_team'),
                        field: "permittedTeam"
                    },
                    {
                        label: this.$t('male'),
                        field: "male"
                    },
                    {
                        label: this.$t('female'),
                        field: "female"
                    },
                    {
                        label: this.$t('total_permitted_member'),
                        field: "totalPermittedMember"
                    },
                    {
                        label: this.$t('royalty_us'),
                        field: "royalty"
                    },
                    {
                        label: "actions",
                        field: "actions",
                        sortable: false
                    }
                ]
            },
            breadCrumb() {
                return [
                    {
                        text: this.$t('reports'),
                        disabled: false,
                        to: '/reportModules',
                        exact: true

                    },
                    {
                        text: this.$t('mwcarr'),
                        disabled: true,
                    },
                ]
            },
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000,
                    color: ''
                },
                isCreate: false,
                snackbar: false,
                success: false,
                dialogCreate: false,
                dialogEdit: false,
                dialogDelete: false,
                deleteFormData: {},
                popout: true,
                loading: false,
                isLoading: false,
                rows: [],
                totals: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "fiscalYear",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 50
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    serviceID: "",
                    serviceName: "",
                    serviceDescription: ""
                },
              customHeader:[],
                header: "",
                yearFromDate: "",
            };
        },
        mounted() {
            this.loadItems();
            const currentDate = new Date();
            this.currentYear = currentDate.getFullYear();
        },
        created() { this.currentYear(); },
        methods: {
          fetchData(){
            this.header = this.$t('mwcarr')
            this.customHeader = [
              {
                name: this.$t('s_no')
              },
              {
                name: this.$t('peak')
              },
              {
                name: this.$t('permitted_team')
              },
              {
                name: this.$t('permitted_member'),
                children: [this.$t('male'),this.$t('female')]
              },
              {
                name: this.$t('total_permitted_member')
              },
              {
                name: this.$t('royalty_us'),
              }
            ]
            const customData = this.rows.map(res=>({
              countIndex:res.countIndex,
              peaK:res.peak,
              permittedTeam:res.permittedTeam,
              male:res.male,
              female:res.female,
              totalPermittedMember:res.totalPermittedMember,
              royalty:res.royalty
            }))
            return customData
          },
            async submit(data) {
                let param = {
                    peak: data.peak.toString(),
                    permittedTeam: parseInt(data.permittedTeam),
                    male: parseInt(data.male),
                    female: parseInt(data.female),
                    totalPermittedMember: parseInt(data.totalPermittedMember),
                    royalty: parseFloat(data.royalty)
                }
                await axios.post("Mountaining/InsertIntoNMAAsync", param).then(response => {
                    if (response.data.success) {
                        this.snackbar = true
                        this.callbackResponse.data = response.data
                        this.callbackResponse.message = "Royalty Data added Succcessfully"
                        this.callbackResponse.color = "success"

                    }

                }).catch(response => {
                    this.snackbar = true
                    this.callbackResponse.data = response.data
                    this.callbackResponse.message = "Error Adding Royalty Data, Please Contact Admin"
                    this.callbackResponse.color = "error"
                });
                this.loadItems();

            },
            async submitEdit(data) {
                let param = {
                    peak: data.peak.toString(),
                    permittedTeam: parseInt(data.permittedTeam),
                    male: parseInt(data.male),
                    female: parseInt(data.female),
                    totalPermittedMember: parseInt(data.totalPermittedMember),
                    royalty: parseFloat(data.royalty),
                    mountainID: data.mountainID
                }
                await axios.post("Mountaining/UpdateNMAAsync", param).then(response => {
                    this.callbackResponse.data = response.data
                    this.snackbar = true
                    this.callbackResponse.message = "Data updated Successfully"
                    this.callbackResponse.color = "success"

                }).catch(response => {
                    this.snackbar = true
                    this.callbackResponse.data = response.data
                    this.callbackResponse.message = "Error Updating Data, Please Contact Admin"
                    this.callbackResponse.color = "error"
                });
                this.loadItems();

            },
            currentYear() {
                const newdate = new Date().getFullYear().toString();
                this.yearFromDate = newdate;
            },
            getChangedList() {
                this.loadItems();
            },
            createRemoveArray(index) {
                this.rows.splice(index, 1)
            },
            addTableData() {
                this.rows.push({
                    fiscalYear: '',
                    nrs: '',
                    exchangeRate: '',
                    amountUsd: '',
                    change: '',
                    isEdit: false,
                    isCreate: true,
                })
            },
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true
                    this.loadItems();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false
                }
            },
            editItem(props) {
                this.editedData.mountainID = props.mountainID;
                this.dialogEdit = true;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountaining/DeleteNMAAsync/" + props.mountainID;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = {}
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                //console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Year: this.yearFromDate + '-01-01',
                };
                axios.post("Mountaining/GetNMAListForAdminAsync", param)
                    .then(({ data }) => {
                        this.rows = data.listvalue;
                        this.totals = data.total;
                        this.rows.map(res => {
                            this.$set(res, 'isEdit', false);
                            this.$set(res, 'isCreate', false);
                            return res;
                        })
                        this.totalRecords = data.totalCount;
                        if (this.rows.length == 0) {
                            this.addTableData();
                            this.totalRecords = 1
                        }

                    })
                    .catch(err => {
                        console.log(err)
                    });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    button {
        z-index: 1;
    }

    ::v-deep .v-text-field, ::v-deep .v-text-field--solo {
        width: 100px !important;
    }

    ::v-deep .v-data-table__wrapper {
        width: 100% !important;
    }

    .border {
        border: 1px solid black !important;
    }

    table, td {
        height: 40px !important;
        border: 1px solid black !important;
        border-collapse: collapse;
    }

    thead {
        background-color: #1976D2;
        color: white;
        z-index: 1;
        top: 0;
        position: sticky;

        th {
            height: 40px !important;
            border: 1px solid !important;
            color: white !important;
            text-align: center !important;
            border-color: white;
            font-size: 15px !important;
        }
    }

    ::v-deep .v-text-field {
        width: 160px !important;
    }
</style>
