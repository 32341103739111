<template>
    <v-container fluid>
        <v-tabs v-model="tab" >
            <v-tab href="#tab-dotHotel"> {{$t('dot_hotel')}}</v-tab>
            <v-tab href="#tab-dotMountain">{{$t('dot_mountain')}}</v-tab>
            <v-tab-item
                    value="tab-dotHotel"
            >
                <DotHotelReportTable v-if="tab==='tab-dotHotel'"></DotHotelReportTable>
            </v-tab-item>
            <v-tab-item
                    value="tab-dotMountain"
            >
                <DotMountainReportTable v-if="tab==='tab-dotMountain'"></DotMountainReportTable>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
    export default {
        name: "HotelReportTable",
        props: [
            'moduleName'
        ],
        components: {
            DotHotelReportTable: () => import(/* webpackChunkName: "AirDetailReportTable" */ './DotHotelReportTable'),
            DotMountainReportTable: () => import(/* webpackChunkName: "AirCategoryReportTable" */ './DotMountainReportTable'),
        },
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            }
        },
        data() {
            return {
                tab: 'tab-dotHotel',
            };
        },
        methods:{},
        mounted() {

        }
    }
</script>

<style scoped lang="scss">

</style>