<template>
    <v-container fluid>
        <v-tabs v-model="tab" >
            <v-tab href="#tab-airDetail"> {{$t('air_details')}}</v-tab>
            <v-tab href="#tab-airCategory">{{$t('air_category')}}</v-tab>
            <v-tab-item
                    value="tab-airDetail"
            >
                <AirDetailReportTable v-if="tab==='tab-airDetail'"></AirDetailReportTable>
            </v-tab-item>
            <v-tab-item
                    value="tab-airCategory"
            >
                <AirCategoryReportTable v-if="tab==='tab-airCategory'"></AirCategoryReportTable>
            </v-tab-item>
        </v-tabs>
    </v-container>
</template>

<script>
    export default {
        name: "HotelReportTable",
        props: [
            'moduleName'
        ],
        components: {
            AirDetailReportTable: () => import(/* webpackChunkName: "AirDetailReportTable" */ './AirDetailReportTable'),
            AirCategoryReportTable: () => import(/* webpackChunkName: "AirCategoryReportTable" */ './AirCategoryReportTable'),
        },
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            }
        },
        data() {
            return {
                tab: 'tab-airDetail',
            };
        },
        methods:{},
        mounted() {

        }
    }
</script>

<style scoped lang="scss">

</style>